import API from './Api';

class InventoriesService extends API {
	constructor(controller) {
		super(controller);
	}

	searchByBranch = (id, mostrarSinInventario) => {
		return this.API.get(`${this.controller}/branch/${id}?mostrarSinInventario=${mostrarSinInventario == true}`);
	};

	searchByStorage = (id, mostrarSinInventario) => {
		return this.API.get(`${this.controller}/storage/${id}?mostrarSinInventario=${mostrarSinInventario == true}`);
	};

	searchByItem = (id, mostrarSinInventario) => {
		return this.API.get(`${this.controller}/item/${id}?mostrarSinInventario=${mostrarSinInventario == true}`);
	};

	searchByClasification = (id, branchId, mostrarSinInventario) => {
		return this.API.get(`${this.controller}/clasification/${id}?branchId=${branchId}&mostrarSinInventario=${mostrarSinInventario == true}`);
	}

	searchByCodes = (itemsCodes, clasificationId, branchId, mostrarSinInventario) => {
		return this.API.get(`${this.controller}/codes?mostrarSinInventario=${mostrarSinInventario == true}`, { itemsCodes, clasificationId, branchId });
	}

	getItemTracking = (itemId, storageId) => {
		return this.API.get(`${this.controller}/tracking/${itemId}/${storageId}`);
	};

	getPendingItemsToTransform = (date, branchId) => {
        return this.API.get(`${this.controller}/ItemsPendingToTransform?DeliveryDate=${date.toJSON()}&SupplierId=${branchId}`);
    }

	getAdjustmentMotivations = () => {
		return this.API.get(`${this.controller}/adjustmentmotivations`);
	};

	saveAdjustment = (adjustment) => {
		return this.API.post(`${this.controller}/adjustment`, adjustment);
	}

	getNextFolio = () => {
		return this.API.get(`${this.controller}/adjustment/next-folio`);
	}
}

let service = new InventoriesService('inventory');

export default service;
