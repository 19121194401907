import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import PropTypes from 'prop-types';
import Moment from 'moment';
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import Modal from 'components/Modal';
import PurchaseOrderReceiveForm from "./Form";
// UI Components
import {
    withStyles,
    Grid,
    InputLabel,
    FormControlLabel,
    Checkbox,
} from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { Portlet, PortletContent, Table, Select } from 'components';

//Services
import { WithTicket, WithSession, WithNotifications } from "hoc";
import inventoriesService from 'services/inventoriesService';
import suppliersService from 'services/suppliersService';
import storagesService from 'services/storagesService';

import TrackingInventoryItem from './Tracking';
import classificationsService from "services/classificationsService";

//Assets
import styles from './styles';
import { formatCurrency, formatNumber } from 'utils/numbers';

class SearchInventories extends Component {
    static propTypes = {
        classes: PropTypes.object.isRequired
    };

    state = {
        selectedItems: [],
        requisitions: [],
        startDate: Moment().startOf('week').toDate(),
        endDate: Moment.utc().endOf('week').toDate(),
        statusId: 1,
        loading: false,
        branches: [],
        storages: [],
        items: [],
        details: [],
        loadingDetails: false,
        mostrarSinInventario: false,
    };

    columns = [
        // {
        //     title: "Id",
        //     field: 'id',
        // },
        {
            title: "Artículo",
            field: 'itemDetail',
        },
        {
            title: "Cantidad",
            field: 'quantity',
            render: (row) => <span>{formatNumber(row.quantity)}</span>
        },
        {
            title: "U/M",
            field: 'measurementUnit'
        },
        {
            title: "Costo Prom.",
            field: 'averageCost',
            render: (row) => <span>{formatCurrency(row.averageCost)}</span>
        },
        {
            title: "Almacen",
            field: 'storageName'
        },
    ];

    statuses = [
        { value: 0, label: "Todos" },
        { value: 1, label: "Nueva" },
        { value: 2, label: "Pendiente de revisión" },
        { value: 3, label: "Confirmada y bloqueada para edicion" },
        { value: 4, label: "Recibida Parcialmente" },
        { value: 5, label: "Recibida" },
        // { value: 6, label: "Cancelada" },
    ]

    componentDidMount = () => {
        this.getData();
    }

    getData = () => {
        this.getBranches();
        this.getClasifications();
    }

    componentDidUpdate = (prevProps, prevState) => {
        const {
            startDate,
            endDate,
            branchId,
            storageId,
            clasificationId,
            itemId,
            mostrarSinInventario,
        } = this.state;

        if (prevState.endDate != endDate && endDate < startDate)
            this.setState({ startDate: endDate }, this.search);
        else if (prevState.startDate != startDate && startDate > endDate)
            this.setState({ endDate: startDate }, this.search);
        else if (prevState.branchId != branchId || prevState.storageId != storageId || prevState.itemId != itemId || prevState.clasificationId != clasificationId)
            this.search();
        else if (prevState.mostrarSinInventario != mostrarSinInventario)
            this.search();

        if (prevState.branchId != branchId) {
            this.getStorages();
            this.getItems();
        }

        if (prevState.storageId != storageId) {
            this.getItems();
        }
    }

    getStatus = (row) => {
        const status = this.statuses.find(x => x.value == row.statusId);
        return status ? status.label.replace(/s*$/, "") : "";
    }

    getBranches = async () => {
        const response = await suppliersService.getInternal();
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                branches: response.data.data.map((i) => ({
                    value: i.supplierId,
                    label: i.supplierName,
                }))
            });

            if (response.data && response.data.data && response.data.data.length > 0) {
                this.setState({ branchId: response.data.data[0].supplierId });
            }
        }
    }

    getStorages = async () => {
        const {
            branchId
        } = this.state;

        this.setState({ storages: [] });

        if (!branchId) {
            return;
        }

        const response = await storagesService.getById(branchId);
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                storages: response.data.data.map((i) => ({
                    value: i.storageId,
                    label: i.name,
                }))
            });
        }
    }

    getClasifications = async () => {
        this.setState({ clasifications: [] });

        const response = await classificationsService.getAll();
        if (response.ok && response.data.status != "Fail") {
            this.setState({
                clasifications: response.data.data.map(i => ({
                    value: i.clasificationId, label: i.name, storageId: i.storageId
                }))
            });
        }
    }

    getItems = async () => {
        const {
            branchId,
        } = this.state;

        this.setState({ items: [] });

        if (!branchId) {
            return;
        }

        try {
            const response = await suppliersService.getSupplierItems(branchId);
            this.setState({ items: response && response.data ? response.data.data.map((i) => ({ value: i.itemId, label: i.item.detail || '', ...i })) : [] });
        } catch (e) {
            console.log(e);
        }
    };

    getItemDetail = async (row) => {
        const {
            items
        } = this.state;

        if (row.details) {
            return;
        }

        let tmp = [...items];
        let item = items.find(x => x.itemId === row.itemId);

        if (!row || !row.itemId || !row.storageId) {
            return;
        }

        try {
            const response = await inventoriesService.getItemTracking(row.itemId, row.storageId);
            item.details = response.data.data;
            this.setState({ items: tmp });
        } catch (e) {
            console.log(e);
        }
    }

    search = async () => {
        const {
            branchId,
            storageId,
            itemId,
            clasificationId,
            mostrarSinInventario,
        } = this.state;

        this.setState({ loading: true });

        try {
            let response = null;
            if (itemId) {
                response = await inventoriesService.searchByItem(itemId, mostrarSinInventario);
            }
            else if (clasificationId) {
                response = await inventoriesService.searchByClasification(clasificationId, branchId, mostrarSinInventario);
            }
            else if (storageId) {
                response = await inventoriesService.searchByStorage(storageId, mostrarSinInventario);
            }
            else {
                response = await inventoriesService.searchByBranch(branchId, mostrarSinInventario);
            }

            if (response.ok && Array.isArray(response.data.data)) {
                this.setState({ requisitions: response.data.data });
            }
            else {
                this.props.addNotification({ message: "Error realizando busqueda", options: { variant: "error" } });
            }
        }
        catch (e) {
            console.log(e);
        }

        this.setState({ loading: false });

    }

    getTitle = () => {
        return "Inventario";
    }

    renderFilters = () => {
        const {
            branchId,
            storageId,
            clasificationId,
            itemId,
            branches,
            storages,
            items,
            clasifications,
            mostrarSinInventario,
        } = this.state;

        const {
            classes
        } = this.props;

        let tmpClassifications = [...(clasifications || [])];
        let tmpItems = [...(items || [])];

        if (storageId) {
            tmpClassifications = tmpClassifications.filter(x => x.storageId == storageId || x.value == 0);
            tmpItems = storageId == 0 ? tmpItems : items.filter(x => x.storageId == storageId);
        }

        if (clasificationId) {
            tmpItems = tmpItems.filter(x => x.clasificationId == clasificationId || x.value == 0);
        }

        return (
            <Grid container className={classes.root} justify="space-beetwen" style={{ padding: '15px' }}>
                <Grid item xs={12} md={6} lg={3} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Sucursal</InputLabel>
                        <Select
                            key={branches.length}
                            value={branchId}
                            onChange={(selected) => {
                                this.setState({ branchId: selected.value });
                            }}
                            options={branches}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={2} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Almacen</InputLabel>
                        <Select
                            isClearable
                            key={branchId}
                            value={storageId}
                            onChange={(selected) => {
                                this.setState({ storageId: selected.value });
                            }}
                            options={storages}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Clasificación</InputLabel>
                        <Select
                            isClearable
                            key={`B-${branchId}-S${storageId}`}
                            value={clasificationId}
                            onChange={(selected) => {
                                this.setState({ clasificationId: selected.value });
                            }}
                            options={tmpClassifications}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={3} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <InputLabel>Artículo</InputLabel>
                        <Select
                            isClearable
                            key={`BID-${branchId}-SID:${storageId}`}
                            value={itemId}
                            onChange={(selected) => {
                                this.setState({ itemId: selected.value });
                            }}
                            options={tmpItems}
                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                            menuPortalTarget={document.body}
                            menuPosition={'absolute'}
                            menuPlacement={'bottom'}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={1} alignContent="center" className={classes.item}>
                    <Grid
                        direction="column"
                        justify="center"
                        className={
                            classNames(
                                classes.inputRoot
                            )
                        }
                    >
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={mostrarSinInventario}
                                    onChange={(e) => this.setState({ mostrarSinInventario: e.target.checked })}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Todos"
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    renderTable = () => {
        const {
            requisitions,
            selectedItems,
            copiyng,
            loading,
        } = this.state;

        let items = requisitions;

        return (
            <React.Fragment>
                <Grid
                    container
                    spacing={3}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{ paddingLeft: '15px', paddingRight: "15px" }}
                >
                    {this.renderFilters()}
                    <Table
                        columns={this.columns}
                        items={copiyng ? selectedItems : items}
                        options={{
                            pageSize: copiyng ? 100 : 10,
                            toolbar: false,
                            search: false,
                            detailPanelType: "single",
                        }}
                        onSelectionChange={(selectedItems) => {
                            this.setState({ selectedItems });
                        }}
                        isLoading={loading}
                        detailPanel={({ rowData }) => {
                            return (
                                <TrackingInventoryItem
                                    itemId={rowData.itemId}
                                    storageId={rowData.storageId}
                                />
                            );
                        }}
                    />
                </Grid>
            </React.Fragment>
        );
    }

    renderViewModal = () => {
        const {
            idToShow
        } = this.state;

        return (
            <Modal
                title="Recibir mercancia de orden de compra"
                isOpen={this.state.idToShow != null}
                onRequestClose={() => {
                    this.setState({ idToShow: null });
                }}
                style={{ width: '95%', height: '85%' }}
            >
                <PurchaseOrderReceiveForm
                    key={idToShow}
                    id={idToShow}
                    hideLayout
                    onClose={() => this.setState({ idToShow: null })}
                    onSave={() => {
                        this.setState({ idToShow: null });
                        this.search();
                    }}
                />
            </Modal>
        );
    }

    render() {
        const {
            classes,
        } = this.props;

        return (
            <DashboardLayout title={this.getTitle()}>
                <div className={classes.root}>
                    <div className={classes.content}>
                        <Portlet className={classes.root}>
                            <PortletContent noPadding>
                                {this.renderViewModal()}
                                {this.renderTable()}
                            </PortletContent>
                        </Portlet>
                    </div>
                </div>
            </DashboardLayout>
        );
    }
}

export default withRouter(withSnackbar(WithNotifications(WithSession(WithTicket(withStyles(styles)(SearchInventories))))));